import React from 'react';
import './image.scss';
import Image from './Image';
import HoverableImage from './HoverableImage';

type Props = {
  className?: string,
  id?: string,
  alt: string,
  top?: string,
  middle?: string,
  bottom?: string,
  small?: boolean
}

const HoverableFloatingImage: React.FC<Props> = (props) => {
  const { className, top, middle, bottom, alt, small, ...otherProps } = props;

  return (
    <div className={`floating-image ${className}`} {...otherProps}>
      {top && <HoverableImage small={small} src={top} alt={`${alt} top`} />}
      {middle && <Image className={`${small ? 'img-small' : ''}`} src={middle} alt={`${alt} middle`} />}
      {bottom && <Image className={`${small ? 'img-small' : ''}`} src={bottom} alt={`${alt} bottom`} />}
    </div>
  );
}

HoverableFloatingImage.defaultProps = {
  className: '',
  alt: 'Someone forgot to add an alt tag on this picture. Have a nice day.',
  small: undefined
}

export default HoverableFloatingImage;
