import React from 'react';
import './element.scss';

type Props = {
  id?: string,
  className?: string,
}

const Element: React.FC<Props> = (props) => {
  const {
    className,
    ...otherProps
  } = props;

  return (
    <div
      className={`element ${className}`}
      {...otherProps}
    />
  );
}

Element.defaultProps = {
  className: ''
}

export default Element;
