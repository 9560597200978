import React, { useEffect, useState } from 'react';
import './modal-container.scss';
import { Button } from '../Button';

type Props = {
  delay?: number,
  children?: React.ReactNode
}

const ModalContainer: React.FC<Props> = (props) => {
  const { children, delay } = props;
  const [showModal, setShowModal] = useState(false);

  if (delay) {
    useEffect(() => {
      setTimeout(() => {
        setShowModal(true);
      }, delay);
    }, []);
  }

  return (
    <div className={`modal-container${showModal ? '' : '-hidden'}`}>
      <div className='modal-content'>
        <div className='modal-close-container'>
          <Button onClick={() => setShowModal(false)} secondary>
            Close
          </Button>
        </div>
        {children}
      </div>
      <div className="modal-background" onClick={() => setShowModal(false)} />
    </div>
  );
}

ModalContainer.defaultProps = {
  delay: undefined,
  children: undefined
}

export default ModalContainer;
