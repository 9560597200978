import React from 'react';
import './job-tech-element.scss';
import {
  Element,
  FloatingImage
} from '../../../components';

type Props = {
  technology: any,
}

const JobTechElement: React.FC<Props> = (props) => {
  const { technology, ...otherProps } = props;

  return (
    <Element className='job-tech-container' {...otherProps}>
      <div className='job-tech-image-container'>
        <FloatingImage
          bottom={technology.image}
          alt={`${technology.name} icon`} />
        {technology.image2 &&
          <FloatingImage
            bottom={technology.image2}
            alt={`${technology.name} icon 2`} />
        }
        {technology.image3 &&
          <FloatingImage
            bottom={technology.image3}
            alt={`${technology.name} icon 3`} />
        }
      </div>
      <div>
        <h4>
          {technology.name}
        </h4>
        <p>
          {technology.description}
        </p>
      </div>
    </Element>
  );
}

JobTechElement.defaultProps = {

}

export default JobTechElement;
