import React from 'react';
import './job-tech-section.scss';
import {
  SectionThreeElement,
  SectionOneElement,
  Element
} from '../../../components';
import { JobTechElement } from '../../elements';

type Props = {
  data: {
    title: string,
    tech: Array<{
      name: string,
      image: string,
      description: string
    }>
  }
}

const JobTechSection: React.FC<Props> = (props) => {
  const { data, ...otherProps } = props;

  return (
    <>
      <SectionOneElement>
        <Element>
          <h2>{data.title}</h2>
        </Element>
      </SectionOneElement>
      <SectionThreeElement {...otherProps}>
        {data.tech.map(s => <JobTechElement technology={s} />)}
      </SectionThreeElement>
    </>
  );
}

JobTechSection.defaultProps = {

}

export default JobTechSection;
