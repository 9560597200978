import React from 'react';
import './section.scss';

type Props = {
  id?: string,
  className?: string
}

const SectionThreeElement: React.FC<Props> = (props: Props) => {
  const {
    className,
    ...otherProps
  } = props;

  return (
    <div className={`section section-three-container ${className}`} {...otherProps} />
  );
}

SectionThreeElement.defaultProps = {
  className: ''
}

export default SectionThreeElement;
