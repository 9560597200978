import React from 'react';
import './image.scss';

type Props = {
  className?: string,
  id?: string,
  alt: string,
  src?: string | undefined,
}

const Image: React.FC<Props> = (props) => {
  return (
    <img {...props} />
  );
}

Image.defaultProps = {
  className: '',
  alt: 'Someone forgot to add an alt tag on this picture. Have a nice day.',
}

export default Image;
