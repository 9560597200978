
export const submitContact = (
  email: string,
  subject: string,
  message: string,
  honeypot: boolean
) => {
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      email,
      subject,
      message,
      _cup_meda: honeypot
    })
  };


  return fetch('https://submit-form.com/6LztoF5ojSbCXi8BiFh82', options)
    .then((response) => {
      console.log(response);
    });
}

export const submitApplication = (
  page: string,
  name: string,
  email: string,
  phone: string,
  linkedin: string,
  extras: string,
  coverLetter: string,
  honeypot: boolean
) => {

  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      page,
      email,
      name,
      phone,
      linkedin,
      extras,
      coverLetter,
      _cup_meda: honeypot
    })
  };

  return fetch('https://submit-form.com/8w9rXwEEI9t26oDo6Kbl0', options)
    .then((response) => {
      console.log(response);
    });
}

export const submitSubscribe = (
  name: string,
  email: string,
  honeypot: boolean
) => {

  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      email,
      name,
      _cup_meda: honeypot
    })
  };

  return fetch('https://submit-form.com/8w9rXwEEI9t26oDo6Kbl0', options)
    .then((response) => {
      console.log(response);
    });
}
