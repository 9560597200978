import { global } from '../global';
import { paths, pathnames } from '../../paths';

export const getStarted = {
  global,
  path: paths.getStarted,
  pathname: pathnames[paths.getStarted],
  content: {
    sections: [
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
    ]
  }
};
