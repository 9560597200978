import React from 'react';
import {
  Element,
  PaddedSectionOneElement
} from '../../../components';

type Props = {
  data: {
    title: string,
    subtitle?: string,
  },
  children?: React.ReactNode
}

const PaddedHeadlineSection: React.FC<Props> = (props) => {
  const {
    data,
    children
  } = props;

  return (
    <PaddedSectionOneElement>
      <Element>
        <h1 className='headline'>
          {data.title}
        </h1>
        {data.subtitle &&
          <p>{data.subtitle}</p>
        }
        {children}
      </Element>
    </PaddedSectionOneElement>
  );
}

PaddedHeadlineSection.defaultProps = {

}

export default PaddedHeadlineSection;
