import { pages } from './pages';
import { assets } from './assets';
import {
  paths,
  pathnames
} from './paths';
import { consts } from './consts';

export const model = {
  pages,
  assets,
  paths,
  pathnames,
  consts,
};
