import React, { useState } from 'react';
import './job-form-element.scss';
import {
  Element,
  Form,
  FormCaptcha,
  FormButton,
  FormInput
} from '../../../components';
import { emails, forms } from '../../../services';

type Props = {
  pageTitle: string,
}

const JobFormElement: React.FC<Props> = (props) => {
  const { pageTitle } = props;

  let [formCaptcha, setCaptcha] = useState('');

  let [formNameError, setFormNameError] = useState('');
  let [formEmailError, setFormEmailError] = useState('');

  let [formPhoneError, setFormPhoneError] = useState('');
  let [formLinkedinError, setFormLinkedinError] = useState('');
  let [formCoverLetterError, setFormCoverLetterError] = useState('');
  let [formCaptchaError, setFormCaptchaError] = useState('');

  let [formButtonActive, setFormButtonActive] = useState(true);
  let [formButtonText, setFormButtonText] = useState('SUBMIT');

  let [formCupMeda, setFormCupMeda] = useState(false);

  let getFormName: () => string = () => '';
  let getFormEmail = getFormName;
  let getFormPhone = getFormEmail;
  let getFormLinkedin = getFormPhone;
  let getFormExtras = getFormLinkedin;
  let getFormLetter = getFormExtras;

  const onSubmit = (e: any) => {
    e.preventDefault();

    let error = false;

    setFormButtonActive(false);

    if (getFormName().length === 0) {
      setFormNameError('Name is required!');
      error = true;
    } else if (getFormName().length !== 0) {
      setFormNameError('');
    }

    if (!emails.isEmailValid(getFormEmail())) {
      setFormEmailError('Email not valid!');
      error = true;
    } else if (formEmailError.length !== 0) {
      setFormEmailError('');
    }

    if (getFormEmail().length === 0) {
      setFormEmailError('Email is required!');
      error = true;
    } else if (getFormEmail().length !== 0) {
      setFormEmailError('');
    }

    if (getFormPhone().length === 0) {
      setFormPhoneError('Phone is required!');
      error = true;
    } else if (getFormPhone().length !== 0) {
      setFormPhoneError('');
    }

    if (getFormLinkedin().length === 0) {
      setFormLinkedinError('LinkedIn link is required!');
      error = true;
    } else if (getFormLinkedin().length !== 0) {
      setFormLinkedinError('');
    }

    if (getFormLetter().length === 0) {
      setFormCoverLetterError('Cover letter is required!');
      error = true;
    } else if (getFormLetter().length !== 0) {
      setFormCoverLetterError('');
    }

    if (!formCaptcha || formCaptcha.length === 0) {
      setFormCaptchaError('You must fill the captcha!');
      error = true;
    } else if (formCaptcha.length !== 0) {
      setFormCaptchaError('');
    }

    if (error) {
      setFormButtonActive(true);
      return;
    }

    setFormButtonText('SENDING...');

    forms.submitApplication(
      pageTitle,
      getFormName(),
      getFormEmail(),
      getFormPhone(),
      getFormLinkedin(),
      getFormExtras(),
      getFormLetter(),
      formCupMeda
    ).then(() => {
      setFormButtonText('SENT');
    }).catch(() => {
      setFormButtonText('Something happened. Try again!');
      setFormButtonActive(true);
    });

  }

  return (
    <Element id='application-form'>
      <Form target='_self' onSubmit={onSubmit}>
        <input
          type='checkbox'
          name='_cup_meda'
          style={{ display: 'none' }}
          tabIndex={-1}
          autoComplete='off'
          checked={formCupMeda}
          onChange={(e: any) => setFormCupMeda(e.target.checked)}
        />
        <FormInput
          name='Full name'
          error={formNameError}
          callback={(func: () => string) => getFormName = func}
          required
        >
          Name Surname
          </FormInput>
        <FormInput
          name='Email'
          error={formEmailError}
          callback={(func: () => string) => getFormEmail = func}
          required
        >
          your@email.com
        </FormInput>
        <FormInput
          name='Phone'
          error={formPhoneError}
          callback={(func: () => string) => getFormPhone = func}
          required
        >
          +385 xx xxx xxxx
        </FormInput>
        <FormInput
          name='Link to Your LinkedIn profile'
          error={formLinkedinError}
          callback={(func: () => string) => getFormLinkedin = func}
          required
        >
          https://linkedin.com/your.profile
        </FormInput>
        <FormInput
          name='Links to Your work'
          callback={(func: () => string) => getFormExtras = func}
        >
          Links to Github, Bitbucket, Behance, Dribble...
        </FormInput>
        <FormInput
          name='Cover letter'
          error={formCoverLetterError}
          type='textarea'
          callback={(func: () => string) => getFormLetter = func}
          required
        >
          Describe your motivation...
        </FormInput>
        <FormCaptcha
          sitekey='6LfmKdAUAAAAAG5ixdrZuzF4Yk8CnpmzumaCHdFK'
          error={formCaptchaError}
          onChange={setCaptcha}
        />
        <FormButton disabled={!formButtonActive}>
          {formButtonText}
        </FormButton>
      </Form>
    </Element>
  );
}

JobFormElement.defaultProps = {

}

export default JobFormElement;
