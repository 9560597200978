import React from 'react';
import './placeholder-component.scss';

type Props = {

}

const PlaceholderComponent: React.FC<Props> = (props) => {

  return (
    <div>
      Placeholder component
    </div>
  );
}

PlaceholderComponent.defaultProps = {

}

export default PlaceholderComponent;
