import React, { useEffect } from 'react';
import jQuery from 'jquery';
import './text-animation-wrapper.scss';

type Props = {
  children?: React.ReactNode
}

const TextAnimationWrapper: React.FC<Props> = (props) => {
  const { children } = props;

  /*
    useEffect(() => {
  
      (function ($) {
        //@ts-ignore
        $.fn.visible = function (partial) {
  
          var $t = $(this),
            $w = $(window),
            viewTop = $w.scrollTop(),
            //@ts-ignore
            viewBottom = viewTop + $w.height(),
            //@ts-ignore
            _top = $t.offset().top,
            //@ts-ignore
            _bottom = _top + $t.height(),
            compareTop = partial === true ? _bottom : _top,
            compareBottom = partial === true ? _top : _bottom;
  
          //@ts-ignore
          return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
  
        };
      })(jQuery);
  
      var win = jQuery(window);
      var elements = jQuery(".section");
      console.log(elements.length);
  
  
      elements.each(function (i, el) {
        var element = jQuery(el);
        //@ts-ignore
        if (element.visible(true)) {
          element.addClass("already-visible");
        }
      });
  
      win.scroll(function (event) {
        elements.each(function (i, el) {
          const element = jQuery(el);
          //@ts-ignore
          if (element.visible(true)) {
            element.addClass("come-in");
          }
        });
      });
  
    }, []);
    */

  return (
    <>
      {children}
    </>
  );
}

TextAnimationWrapper.defaultProps = {
  children: undefined
}

export default TextAnimationWrapper;
