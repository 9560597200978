import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import {
  applyMiddleware,
} from "redux";


export function collectMiddlewares() {
  const middlewares = [];
  middlewares.push(thunk);

  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(createLogger());
  }

  return middlewares;
}

export default () => applyMiddleware(...collectMiddlewares());

