import React from 'react';
import './cta-section.scss';
import {
  Element,
  Button,
  SectionOneElement
} from '../../../components';

type Props = {
  data: {
    title: string,
    primary?: {
      text: string,
      link: string,
    },
    secondary?: {
      text: string,
      link: string
    },
    accent?: {
      text: string,
      link: string
    }
  }
}

const CtaSection: React.FC<Props> = (props) => {
  const {
    data,
    ...otherProps
  } = props;

  return (
    <SectionOneElement {...otherProps}>
      <Element className='cta-container'>
        <h2>
          {data.title}
        </h2>
        <div>
          {data.primary &&
            <Button to={data.primary.link} primary>
              {data.primary.text}
            </Button>
          }
          {data.accent &&
            <Button to={data.accent.link} accent>
              {data.accent.text}
            </Button>
          }
          {data.secondary &&
            <Button to={data.secondary.link} secondary>
              {data.secondary.text}
            </Button>
          }
        </div>
      </Element>
    </SectionOneElement>
  );
}

CtaSection.defaultProps = {
}

export default CtaSection;
