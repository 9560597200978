import React from 'react';
import './small-image-card-element.scss';
import {
  Element,
  SmallCard,
  HoverableFloatingImage
} from '../../../components';

type Props = {
  data: {
    title: string,
    text: string,
    link: string,
    image: {
      top?: string,
      middle?: string,
      bottom?: string,
      alt: string
    }
  },
}

const SmallImageCardElement: React.FC<Props> = (props) => {
  const {
    data
  } = props;


  return (
    <Element>
      <SmallCard
        title={data.title}
        text={data.text}
        to={data.link}
      >
        <HoverableFloatingImage
          top={data.image.top}
          middle={data.image.middle}
          bottom={data.image.bottom}
          alt={data.image.alt}
        />
      </SmallCard>
    </Element>
  );
}

SmallImageCardElement.defaultProps = {

}

export default SmallImageCardElement;
