import { model } from '../../model';

export const loadAboutData = () => {
  return model.pages.about;
};

export const loadCareerData = () => {
  return model.pages.career;
};

export const loadCareersData = () => {
  return model.pages.careers;
};

export const loadCompanyData = () => {
  return model.pages.company;
};

export const loadContactData = () => {
  return model.pages.contact;
};

export const loadCookiesData = () => {
  return model.pages.cookies;
};

export const loadDesignData = () => {
  return model.pages.design;
};

export const loadDevelopmentData = () => {
  return model.pages.development;
};

export const loadError404Data = () => {
  return model.pages.error404;
};

export const loadGuerillaData = () => {
  return model.pages.guerilla;
};

export const loadHomeData = () => {
  return model.pages.home;
};

export const loadMarketingData = () => {
  return model.pages.marketing;
};

export const loadGetStartedData = () => {
  return model.pages.getStarted;
}

export const loadPeopleData = () => {
  return model.pages.people;
};

export const loadPrivacyData = () => {
  return model.pages.privacy;
};

export const loadWorkData = () => {
  return model.pages.work;
};
