import React from 'react';
import './career-element.scss';
import {
  Element,
  Button,
  Image
} from '../../../components';

type Props = {
  data: {
    link: string,
    title: string,
    location: string,
    image: string,
  }
}

const CareerElement: React.FC<Props> = (props) => {
  const { data } = props;

  return (
    <Element >
      <Button className='career-element-container' to={data.link}>
        <Image src={data.image} alt={`${data.title} image`} />
        <div className='career-element-text'>
          <h3>{data.title}</h3>
          <p>{data.location}</p>
        </div>
      </Button>
    </Element>
  );
}

CareerElement.defaultProps = {

}

export default CareerElement;
