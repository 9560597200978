import { about } from './about';
import { career } from './career';
import { careers } from './careers';
import { company } from './company';
import { contact } from './contact';
import { cookies } from './cookies';
import { design } from './design';
import { development } from './development';
import { error404 } from './error404';
import { guerilla } from './guerilla';
import { home } from './home';
import { marketing } from './marketing';
import { people } from './people';
import { privacy } from './privacy';
import { work } from './work';
import { getStarted } from './getStarted';

export const pages = {
  about,
  career,
  careers,
  company,
  contact,
  cookies,
  design,
  development,
  error404,
  guerilla,
  home,
  marketing,
  people,
  privacy,
  work,
  getStarted
};
