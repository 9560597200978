import { global } from '../global';
import { paths, pathnames } from '../../paths';

const shared = {

};

const wordpressDeveloper = {
  global,
  path: paths.wordpressDeveloper,
  pathname: pathnames[paths.wordpressDeveloper],
  content: {
    sections: [
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
    ]
  }
};

const openJob = {
  global,
  path: paths.openJob,
  pathname: pathnames[paths.openJob],
  content: {
    sections: [
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
    ]
  }
};


export const career = {
  wordpressDeveloper,
  openJob
};
