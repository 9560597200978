import React from 'react';
import { ScreenPaddedContainer } from "../ScreenPaddedContainer";

type Props = {
  children?: React.ReactNode,
  leftSidebar?: React.ReactNode,
  rightSidebar?: React.ReactNode
}

const HolyGrailLayout: React.FC<Props> = (props) => {
  const {
    children,
    leftSidebar,
    rightSidebar
  } = props;
  return (
    <ScreenPaddedContainer className='holy-grail-body'>
      <main className='holy-grail-main'>
        {children}
      </main>
      {leftSidebar &&
        <aside className='holy-grail-left'>
          {leftSidebar}
        </aside>
      }
      {rightSidebar &&
        <aside className='holy-grail-right'>
          {rightSidebar}
        </aside>
      }
    </ScreenPaddedContainer>
  );
}

HolyGrailLayout.defaultProps = {
  children: undefined
};

export default HolyGrailLayout;
