import { assets } from "../assets";
import { WORDPRESS_DEVELOPER_TECH, OPEN_JOB_TECH } from "./jobTech";
import { paths } from "../paths";


export const careers = {
  openJob: {
    link: paths.openJob,
    title: '',
    location: '',
    description: '',
    qualifications: [
    ],
    extras: [
    ],
    image: '',
    responsibilities: [
    ],
    tech: OPEN_JOB_TECH
  },
  wordpressDeveloper: {
    link: paths.wordpressDeveloper,
    title: 'Wordpress Developer',
    location: 'Zagreb, Croatia',
    description: '',
    qualifications: [
      'at least 1 years of experience with WordPress Development',
      'good knowledge of HTML, CSS, Responsive Web Design, Performance Optimization, and Accessibility',
      'knowledge of writing custom WordPress plugins and customizing themes',
      'understanding of RESTful principles and practices',
      'good knowledge of JavaScript',
      'good knowledge of PHP and OOP principles',
      'ability to write clean and testable code',
      'knowledge about recent WordPress releases and standards',
      'desire to work in a team environment',
      'excellent English knowledge',
      'passion for building simple and effective user experiences',
    ],
    extras: [
      'good knowledge of Git and Git workflow',
      'a good debugging and code review routine',
      'a thirst for research and improvement of current development processes and code architecture',
    ],
    image: assets.images.icons.wordpress,
    responsibilities: [
      'development, deployment, and maintenance of various software solutions',
      'defining tasks and giving time estimates',
      'writing clean, maintainable code and testing it',
      'rolling out new features, and reacting to the results learned from user feedback',
      'collaborating with clients',
      'presenting your solutions in front of clients and/or a group of colleagues',
      'using agile methodologies such as SCRUM during development',
      'participating in code reviews, mentorship and other forms of knowledge sharing',
      'continually improving your skills and sharing your knowledge with the others',
      'contributing to shared knowledge of 2Front',
      'enjoy your work'
    ],
    tech: WORDPRESS_DEVELOPER_TECH
  }
};
