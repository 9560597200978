import React from 'react';
import {
  Element,
  SectionOneElement
} from '../../../components';
import './headline.scss';

type Props = {
  data: {
    title: string,
    subtitle?: string,
  },
  className?: string,
  children?: React.ReactNode
}

const HeadlineSection: React.FC<Props> = (props) => {
  const {
    data,
    children,
    className
  } = props;

  return (
    <SectionOneElement>
      <Element>
        <h1 className={`headline ${className}`}>
          {data.title}
        </h1>
        {data.subtitle &&
          <p>{data.subtitle}</p>
        }
        {children}
      </Element>
    </SectionOneElement>
  );
}

HeadlineSection.defaultProps = {
  className: ''
}

export default HeadlineSection;
