import React, { useEffect, useState } from 'react';

type WrappedComponentProps = {
  className?: string,
  data: {
    header: any,
    footer: any
  },
  title: string,
  currentPath: string,
  children?: React.ReactNode,
  leftSidebar?: React.ReactNode,
  rightSidebar?: React.ReactNode,
  addScrollAction?: (action: () => void) => number
}

const onScrollHOC = (WrappedComponent: React.ComponentType<any>): React.FC<WrappedComponentProps> => {
  return (props: WrappedComponentProps) => {

    let [onScrollActions] = useState([() => { }]);

    useEffect(() => {
      window.onscroll = () => {
        onScrollActions.forEach((action) => {
          action();
        });
      }
    }, []);

    return (<WrappedComponent addScrollAction={(action: () => void) => onScrollActions.push(action)} {...props as WrappedComponentProps} />);
  }
}


export default onScrollHOC;
