import React from 'react';
import './image.scss';
import Image from './Image';

type Props = {
  className?: string,
  id?: string,
  alt: string,
  src?: string | undefined,
  small?: boolean
}

const HoverableImage: React.FC<Props> = (props) => {
  const { className, src, alt, small, ...otherProps } = props;

  return (
    <div className={`hoverable-image ${className}`} {...otherProps}>
      <Image className={`${small ? 'img-small' : ''}`} src={src} alt={alt} />
    </div>
  );
}

HoverableImage.defaultProps = {
  className: '',
  alt: 'Someone forgot to add an alt tag on this picture. Have a nice day.',
  small: undefined
}

export default HoverableImage;
