import { global } from '../global';
import { paths, pathnames } from '../../paths';

export const guerilla = {
  global,
  path: paths.guerilla,
  pathname: pathnames[paths.guerilla],
  content: {
    sections: [
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          }
        ]
      },
    ]
  }
};
