import React, {
  useEffect,
  useState
} from 'react';
import './animated-background-hoc.scss';

type WrappedComponentProps = {
  className?: string,
  data: {
    header: any,
    footer: any
  },
  title: string,
  currentPath: string,
  children?: React.ReactNode,
  leftSidebar?: React.ReactNode,
  rightSidebar?: React.ReactNode,
  addScrollAction?: (action: () => void) => number
}

const animatedBackgroundHOC = (WrappedComponent: React.ComponentType<WrappedComponentProps>): React.FC<WrappedComponentProps> => {
  return (props: WrappedComponentProps) => {

    const [xPos, setXPos] = useState('0%');
    const [yPos, setYPos] = useState('0%');

    useEffect(() => {
      setXPos(((Math.random() * 100) - 100) + '%');
      setYPos(((Math.random() * 100) - 100) + '%');
      const interval = setInterval(() => {
        setXPos(((Math.random() * 100) - 100) + '%');
        setYPos(((Math.random() * 100) - 100) + '%');
      }, 9900);
      return () => clearInterval(interval);
    }, []);



    return (
      <>
        <div
          className='animated-background'
          style={{ backgroundPositionY: yPos, backgroundPositionX: xPos }}
        />
        <WrappedComponent {...props as WrappedComponentProps} />
      </>
    );
  }
}


export default animatedBackgroundHOC;
