import React from 'react';
import { Element, FloatingImage, HoverableFloatingImage } from '../../../components';

type Props = {
  hoverable?: boolean
  data: {
    top?: string,
    middle?: string,
    bottom?: string,
    alt: string
  },
  className?: string,
  style?: any,
  small?: boolean
}

const FloatingImageElement: React.FC<Props> = (props) => {
  const {
    data,
    hoverable,
    small,
    ...otherProps
  } = props;

  return (
    <Element {...otherProps}>
      {hoverable && <HoverableFloatingImage
        small={small}
        top={data.top}
        middle={data.middle}
        bottom={data.bottom}
        alt={data.alt}
      />}
      {!hoverable && <FloatingImage
        small={small}
        top={data.top}
        middle={data.middle}
        bottom={data.bottom}
        alt={data.alt}
      />}
    </Element>
  );
}

FloatingImageElement.defaultProps = {
  hoverable: false,
  className: '',
  small: undefined
}

export default FloatingImageElement;
