import React, { RefObject } from 'react';
import './button.scss';
import { Link } from 'gatsby';

type Props = {
  className?: string,
  bold?: boolean,
  primary?: boolean,
  secondary?: boolean,
  accent?: boolean,
  target?: string,
  href?: string,
  to?: string,
  style?: any,
  type?: any,
  ref?: RefObject<HTMLButtonElement> | undefined,
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
}

//@ts-ignore
const Button: React.FC<Props> = React.forwardRef((props, reference) => {
  const {
    className,
    to,
    onClick,
    bold,
    type,
    ref,
    primary,
    secondary,
    target,
    accent,
    ...otherProps
  } = props;

  if (to !== undefined) {
    return (<Link rel='nofollow' target={target} className={`button ${className} ${bold ? 'button-bold' : ''} ${primary ? ('button-primary') : ''} ${secondary ? 'button-secondary' : ''} ${accent ? 'button-accent' : ''}`} to={to} {...otherProps} />);
  } else if (onClick !== undefined || type === 'submit') {
    return (<button ref={reference} className={`button ${className} ${bold ? 'button-bold' : ''} ${primary ? ('button-primary') : ''} ${secondary ? 'button-secondary' : ''} ${accent ? 'button-accent' : ''}`} type={type} onClick={onClick} {...otherProps} />);
  } else {
    return (
      <a rel='nofollow' target={target} className={`button ${className} ${bold ? 'button-bold' : ''} ${primary ? ('button-primary') : ''} ${secondary ? 'button-secondary' : ''} ${accent ? 'button-accent' : ''}`} {...otherProps} />
    );
  }
});

Button.defaultProps = {
  className: '',
  bold: false,
  primary: false,
  secondary: false,
  accent: false,
  to: undefined,
  href: undefined,
  ref: undefined,
  onClick: undefined,
  target: '_blank'
}

export default Button;
