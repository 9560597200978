import React from 'react';
import './employee-element.scss';
import {
  Image,
  Element
} from '../../../components';

type Props = {
  data: {
    image: string,
    name: string,
    position: string
  }
}

const EmployeeElement: React.FC<Props> = (props) => {
  const {
    data
  } = props;

  return (
    <Element className='employee-card'>
      <Image
        src={data.image}
        alt={`A photo of ${data.name} the ${data.name}`}
      />
      <p>{data.name}</p>
      <p>{data.position}</p>
    </Element>
  );
}

EmployeeElement.defaultProps = {

}

export default EmployeeElement;
