import { careers } from './careers';
import {
  OPEN_JOB_TECH,
  WORDPRESS_DEVELOPER_TECH
} from './jobTech';

export const consts = {
  careers,
  OPEN_JOB_TECH,
  WORDPRESS_DEVELOPER_TECH
}
