import React, { useState } from 'react';
import './form.scss';

type Props = {
  children?: string,
  name?: string,
  value?: string,
  error?: string | undefined,
  onChange?: any,
  callback?: any,
  required?: boolean,
  type?: 'input' | 'textarea',
}

const FormInput: React.FC<Props> = (props) => {
  const {
    children,
    name,
    type,
    error,
    onChange,
    value,
    callback,
    ...otherProps
  } = props;

  let [inputValue, setValue] = useState(value);

  if (callback) {
    callback(() => inputValue);
  }

  let element = undefined;
  if (type === 'input') {
    element = (<input className='form-input' type='text' placeholder={children} name={name} value={inputValue} onChange={(e: any) => setValue(e.target.value)} {...otherProps} />);
  } else {
    element = (<textarea className='form-input form-textarea' placeholder={children} name={name} value={inputValue} onChange={(e: any) => setValue(e.target.value)}  {...otherProps} />);
  }


  return (
    <label className='form-element form-element-title'>
      {name && <p>{name}</p>}
      {element}
      {error && <span className='form-error'>{error}</span>}
    </label>
  );
}

FormInput.defaultProps = {
  children: undefined,
  error: undefined,
  type: 'input',
  required: false,
}

export default FormInput;
