import { assets } from "../assets";

const TECH = {
  PLACEHOLDER: {
    name: 'placeholder name',
    image: 'image from assets',
    description: 'placeholder description'
  },
  SLACK: {
    name: 'Slack',
    image: assets.images.icons.slack,
    description: 'Slack is our go-to messaging app. For when you need to send that meme to a teammate or to discuss serious matters.'
  },
  GOOGLE_DRIVE: {
    name: 'Google Drive',
    image: assets.images.icons.drive,
    description: 'We use Google Drive for small file transfer and project data storage.'
  },
  HANGOUTS: {
    name: 'Google Hangouts',
    image: assets.images.icons.hangouts,
    description: 'We Google Hangouts it for online meetings between ourselves and clients.'
  },
  FIGMA: {
    name: 'Figma',
    image: assets.images.icons.figma,
    description: 'With Figma, rapid prototyping and mockups are a breeze and developers know exactly what kind of behavior to look for.'
  },
  ADOBE_C_C: {
    name: 'Adobe Creative Cloud',
    image: assets.images.icons.adobeCC,
    description: 'The whole suit of professional tools is irreplacible in the day work of a designer. Adobe Illustrator and Photoshop are just some of them...'
  },
  REACT_JS: {
    name: 'React.js',
    image: assets.images.icons.react,
    description: 'Powerful and modern Javascript framework enables us to make responsive adjustable web apps.'
  },
  REACT_NATIVE: {
    name: 'React Native',
    image: assets.images.icons.react,
    description: 'Like React.js but for mobile, we use it for mobile development for both apps at the same time.'
  },
  WORDPRESS: {
    name: 'Wordpress',
    image: assets.images.icons.wordpress,
    description: 'The backbone of modern web. We use it as backend for some of our projects or as a standalone website builder.'
  },
  GIT: {
    name: 'Git & Github',
    image: assets.images.icons.git,
    image2: assets.images.icons.github,
    description: 'There is no organized development without Git. We store all our projects on Github.'
  },
  SASS: {
    name: 'Sass',
    image: assets.images.icons.sass,
    description: 'When pure CSS is too weak, we use Sass for that extra development umpf.'
  },
  GATSBY: {
    name: 'Gatsby.js',
    image: assets.images.icons.gatsby,
    description: "React.js is powerful, but Gatsby compiles it into blazing fast static websites. It's pretty great."
  },
  PRODUCTIVE_IO: {
    name: 'Productive.io',
    image: assets.images.icons.productive,
    description: 'We use Productive.io for week to week scheduling and project managment.'
  },
  TYPESCRIPT: {
    name: 'Typescript',
    image: assets.images.icons.typescript,
    description: 'Much more controled and defined environment suits us better than wild wild JS.'
  },
  TS_LINT: {
    name: 'TS Lint',
    image: assets.images.icons.tslint,
    description: 'Typescript might be more organized, but we use TS Lint to make sure everybody follows the same rules.'
  },
  PHP: {
    name: 'PHP',
    image: assets.images.icons.php,
    description: 'Old-reliable, there is no Wordpress without PHP.'
  }
};

const PROJECT_MANAGMENT_TOOLS = [
  TECH.SLACK,
  TECH.PRODUCTIVE_IO,
  TECH.GIT,
  TECH.GOOGLE_DRIVE,
  TECH.HANGOUTS,
];

const TECH_STACK = [
  TECH.ADOBE_C_C,
  TECH.TYPESCRIPT,
  TECH.WORDPRESS
];

export const OPEN_JOB_TECH = [
  ...PROJECT_MANAGMENT_TOOLS,
  ...TECH_STACK
];

export const WORDPRESS_DEVELOPER_TECH = [
  ...PROJECT_MANAGMENT_TOOLS,
  TECH.WORDPRESS,
  TECH.FIGMA,
  TECH.PHP
];

