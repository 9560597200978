import React from 'react';
import './placeholder-fragment.scss';

type Props = {

}

const PlaceholderFragment: React.FC<Props> = (props) => {

  return (
    <div>
      Placeholder fragment
    </div>
  );
}

PlaceholderFragment.defaultProps = {

}

export default PlaceholderFragment;
