import React from 'react';
import './spirit-section.scss';
import {
  SectionTwoElement,
  Element,
  SectionOneElement,
  FloatingImage
} from '../../../components';
import { images } from '../../../assets';

type Props = {

}

const SpiritSection: React.FC<Props> = (props) => {

  return (
    <>
      <SectionOneElement>
        <Element className='spirit-headline-element'>
          <FloatingImage
            bottom={images.floating.careerSpirit3}
            middle={images.floating.careerSpirit2}
            top={images.floating.careerSpirit1}
            alt='2Front spirit'
          />
          <h2>
            What is 2Front spirit?
          </h2>
          <h4>
            2Front spirit is a pathway to many abilities some consider to be unnatural...
          </h4>
          <p>
            It is something we all share in 2Front. A set of virtues we value in our people and enccourage through our projects.
          </p>
        </Element>
      </SectionOneElement>
      <SectionTwoElement>
        <Element className='spirit-element'>
          <h3>2Focused - efficiency</h3>
          <p>If there is a wheel, we try to make it spin faster, not make a new one.</p>
        </Element>
        <Element className='spirit-element'>
          <h3>2Fair - transparency</h3>
          <p>There can be no good relationships without honesty. We enforce it in the office and with our partners.</p>
        </Element>
        <Element className='spirit-element'>
          <h3>2Frank - communication</h3>
          <p>Some may call it talking too much, but we prevent misunderstandings before they become real problems.</p>
        </Element>
        <Element className='spirit-element'>
          <h3>2Flexible - learning</h3>
          <p>We take on projects we can learn from and improve ourselves as people and make our future projects even better.</p>
        </Element>
        <Element className='spirit-element'>
          <h3>2Faithful - consistency</h3>
          <p>Consistency is foundation of all progress and the only way to know where we are now and where to improve.</p>
        </Element>
        <Element className='spirit-element'>
          <h3>2Futuristic - innovation</h3>
          <p>Striving to be better than we were yesterday is the ultimate virtue that we all pursue.</p>
        </Element>
      </SectionTwoElement>
    </>
  );
}

SpiritSection.defaultProps = {

}

export default SpiritSection;
