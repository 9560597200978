import { favicon } from './favicon';
import { floating } from './floating';
import { icons } from './icons';
import { logo } from './logo';

export const images = {
  favicon,
  floating,
  icons,
  logo
};
