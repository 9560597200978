import React from 'react';
import './footer-social-media.scss';
import FooterSMItem from './FooterSMItem';
import { images } from '../../../assets';

type Props = {
  socialMedia: Array<{
    name: string,
    icon: string,
    link: string
  }>
}

const FooterSocialMedia: React.FC<Props> = (props) => {
  const { socialMedia } = props;

  return (
    <ul>
      {socialMedia.map(sm => <FooterSMItem icon={sm.icon} title={sm.name} link={sm.link} />)}
    </ul>
  );
}

FooterSocialMedia.defaultProps = {

}

export default FooterSocialMedia;
