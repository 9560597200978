import React, { useEffect } from 'react';
import './navbar.scss';
import { Image } from '../Image';
import { MainMenu } from '../MainMenu';
import { Link } from 'gatsby';
import { ScreenPaddedContainer } from '../ScreenPaddedContainer';
import { Button } from '../Button';
import { model } from '../../model';

type Props = {
  className?: string,
  currentPath: string,
  addScrollAction?: (action: () => void) => number,
  data: {
    logo: {
      image: string,
      path: string
    },
    navbar: Array<any>
  }
}

const MAIN_MENU_ID = 'main-menu';

const changeStateSetup = (
  ref: React.RefObject<HTMLDivElement>,
  headerState: { prevScrollpos: number, state: number },
  addScrollAction: (action: () => void) => number
) => {
  addScrollAction(() => {
    const header = ref.current;
    let currentScrollPos = window.pageYOffset;

    if (headerState.state === 2 && currentScrollPos === 0) {
      headerState.state = 1;
    } else if (headerState.state === 3 && headerState.prevScrollpos > currentScrollPos) {
      headerState.state = 2;
    } else if (headerState.state === 2 && headerState.prevScrollpos <= currentScrollPos) {
      headerState.state = 3;
    } else if (headerState.state === 1 && currentScrollPos > 200) {
      headerState.state = 3;
    }

    switch (headerState.state) {
      case 2:
        header?.classList.add('header-fixed');
        header?.classList.remove('header-hidden');
        break;
      case 3:
        header?.classList.add('header-hidden');
        header?.classList.remove('header-fixed');
        break;
      default:
        header?.classList.remove('header-fixed');
        header?.classList.remove('header-hidden');
    }

    headerState.prevScrollpos = currentScrollPos;
  });
}

const NavBar: React.FC<Props> = (props) => {
  const {
    currentPath,
    data,
    addScrollAction
  } = props;

  const mainMenuRef = React.createRef<HTMLUListElement>();
  const openRef = React.createRef<HTMLButtonElement>();
  const closeRef = React.createRef<HTMLButtonElement>();
  const headerRef = React.createRef<HTMLDivElement>();


  useEffect(() => {
    let headerState = {
      prevScrollpos: window.pageYOffset,
      state: 1
    };

    if (addScrollAction) changeStateSetup(
      headerRef,
      headerState,
      addScrollAction
    );
  });

  const openMenu = () => {
    const menu = mainMenuRef.current;
    const open = openRef.current;
    const close = closeRef.current;
    document.body.style.overflow = 'hidden';
    if (menu) menu.style.left = '0';
    if (open) open.style.display = 'none';
    if (close) close.style.display = 'inline-block';
  }

  const closeMenu = () => {
    const menu = mainMenuRef.current;
    const open = openRef.current;
    const close = closeRef.current;
    document.body.style.overflow = 'initial';
    if (menu) menu.style.left = '-110vw';
    if (open) open.style.display = 'inline-block';
    if (close) close.style.display = 'none';
  }

  return (
    <header className='header' ref={headerRef}>
      <ScreenPaddedContainer className='navbar-container'>
        <div className='logo-container'>
          <Link to={data.logo.path}>
            <Image
              id='main-menu-logo'
              src={data.logo.image}
              alt='2Front logo'
            />
          </Link>
        </div>
        <Button
          className='navbar-toggle-button'
          ref={openRef}
          secondary
          onClick={openMenu}
        >
          &#9776;
          </Button>
        <Button
          className='navbar-toggle-button header-toggle-close'
          ref={closeRef}
          secondary
          onClick={closeMenu}
          style={{ display: 'none' }}
        >
          &times;
        </Button>
        <MainMenu
          id={MAIN_MENU_ID}
          ref={mainMenuRef}
          data={data.navbar}
          active={currentPath}
        >
        </MainMenu>
        <Button
          to={model.paths.getStarted}
          accent
          className='navbar-cta'
        >
          Get started
        </Button>
      </ScreenPaddedContainer>
    </header>
  );
}

NavBar.defaultProps = {
  className: '',
  addScrollAction: undefined
};

export default NavBar;
