import React from 'react';
import { ScreenPaddedContainer } from "../ScreenPaddedContainer";

type Props = {
  children?: React.ReactNode,
}

const PaddedColumnLayout: React.FC<Props> = (props) => {
  const { children } = props;
  return (
    <ScreenPaddedContainer className='padded-column-body'>
      <main>
        {children}
      </main>
    </ScreenPaddedContainer>
  );
}

PaddedColumnLayout.defaultProps = {
  children: undefined
};

export default PaddedColumnLayout;
