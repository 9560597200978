import React from 'react';
import {
  GoogleMap as ReactGoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps';
import './google-map.scss';

type Props = {

}

const location = { lat: 45.807428, lng: 15.957153 };

const GoogleMapComponent: React.FC<Props> = (props) => {

  return (
    <ReactGoogleMap
      defaultZoom={9}
      defaultCenter={location}
      {...props}>
      <Marker position={location} />
    </ReactGoogleMap>
  );
}

GoogleMapComponent.defaultProps = {

}

export default withScriptjs(withGoogleMap(GoogleMapComponent));
