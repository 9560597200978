import React from 'react';
import './section.scss';

type Props = {
  id?: string,
  className?: string,
  alignment?: 'right' | 'left' | 'center',
}

const SectionTwoElement: React.FC<Props> = (props: Props) => {
  const {
    className,
    alignment,
    ...otherProps
  } = props;

  let alignmentClass = '';
  if (alignment === 'left') {
    alignmentClass = 'section-two-left-container';
  } else if (alignment === 'right') {
    alignmentClass = 'section-two-right-container';
  } else {
    alignmentClass = 'section-two-container';
  }

  return (
    <div className={`section ${alignmentClass} ${className}`} {...otherProps} />
  );
}

SectionTwoElement.defaultProps = {
  className: '',
  alignment: 'center'
}

export default SectionTwoElement;
