import React from 'react';
import { GoogleMap, Element } from '../../../components';

type Props = {
  className?: string,
  data: {
    title: string,
  }
}

const MapElement: React.FC<Props> = (props) => {
  const { className, data, ...otherProps } = props;
  return (
    <Element className={className} {...otherProps}>
      <h2 >
        {data.title}
      </h2>
      <GoogleMap />
    </Element>
  );
}

export default MapElement;
