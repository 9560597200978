import React, { useEffect, useState } from 'react';
import { NewsletterFormElement } from '../../elements';
import { Button } from '../../../components';
import './newsletter-poppup-modal.scss';

type Props = {

}

const NewsletterPoppupModal: React.FC<Props> = (props) => {

  const delay = 10000;

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem('newsletter')) {
      setTimeout(() => {
        setShowModal(true);
        sessionStorage.setItem('newsletter', 'shown');
      }, delay);
    }
  }, []);



  return (
    <div className={`newsletter-poppup${showModal ? '-shown' : ''}`}>
      <Button className='newsletter-poppup-close' secondary onClick={() => setShowModal(false)}>
        &times;
      </Button>
      <NewsletterFormElement callback={() => { setTimeout(() => setShowModal(false), 2500); }} />
    </div>
  );
}

NewsletterPoppupModal.defaultProps = {

}

export default NewsletterPoppupModal;
