import React from 'react';
import './footer-sitemap.scss';
import FooterSitemapItem from './FooterSitemapItem';

type Props = {
  sitemap: Array<{
    path: string,
    name: string
  }>
}

const FooterSitemap: React.FC<Props> = (props) => {
  const { sitemap } = props;

  return (
    <ul className='footer-sitemap'>
      {sitemap.map(s => <FooterSitemapItem to={s.path} text={s.name} />)}
    </ul>
  );
}

FooterSitemap.defaultProps = {

}

export default FooterSitemap;
