import { combineReducers } from 'redux';
import placeholder from "./placeholder";
import { PlaceholderStateContainer } from './placeholder/types';
export { default as middleware } from "./middleware";
export { default as ReduxWrapper } from "../fragments/ReduxWrapper/ReduxWrapper";

export interface RootState extends PlaceholderStateContainer {

};


export const createRootReducer = () => combineReducers({
  placeholder,
});
