import React from 'react';
import {
  Element,
  WideCard
} from '../../../components';

type Props = {
  data: {
    id?: string,
    title?: string,
    text?: string,
    alignment?: undefined | 'right' | 'left',
    cta?: string,
    link?: string,
  },
  className?: string,
  children?: React.ReactNode
}

const WideCardElement: React.FC<Props> = (props) => {
  const {
    data,
    children,
    className
  } = props;


  return (
    <Element className={className}>
      <WideCard
        id={data.id}
        text={data.text}
        title={data.title}
        right={data.alignment === 'right'}
        left={data.alignment === 'left'}
        cta={data.cta}
        to={data.link}
      >
        {children}
      </WideCard>
    </Element>
  );
}

WideCardElement.defaultProps = {
  children: undefined,
  className: ''
}

export default WideCardElement;
