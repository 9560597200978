import React, { useState } from 'react';
import './contact-form.scss';
import {
  Form,
  FormInput,
  FormButton,
  FormCaptcha
} from '../../../components';
import { forms, emails } from '../../../services';

type Props = {

}

const ContactFormElement: React.FC<Props> = (props) => {

  let [formCaptcha, setCaptcha] = useState('');

  let [formEmailError, setFormEmailError] = useState('');
  let [formSubjectError, setFormSubjectError] = useState('');
  let [formMessageError, setFormMessageError] = useState('');
  let [formCaptchaError, setFormCaptchaError] = useState('');

  let [formButtonActive, setFormButtonActive] = useState(true);
  let [formButtonText, setFormButtonText] = useState('SUBMIT');

  let [formCupMeda, setFormCupMeda] = useState(false);


  let getFormMessage: () => string = () => '';
  let getFormEmail = getFormMessage;
  let getFormSubject = getFormEmail;

  const onSubmit = (e: any) => {
    e.preventDefault();

    let error = false;

    setFormButtonActive(false);

    if (!emails.isEmailValid(getFormEmail())) {
      setFormEmailError('Email not valid!');
      error = true;
    } else if (formEmailError.length !== 0) {
      setFormEmailError('');
    }

    if (getFormSubject().length === 0) {
      setFormSubjectError('Subject is required!');
      error = true;
    } else if (getFormSubject().length !== 0) {
      setFormSubjectError('');
    }

    if (getFormMessage().length === 0) {
      setFormMessageError('Message is required!');
      error = true;
    } else if (getFormMessage().length !== 0) {
      setFormMessageError('');
    }

    if (!formCaptcha || formCaptcha.length === 0) {
      setFormCaptchaError('You must fill the captcha!');
      error = true;
    } else if (formCaptcha.length !== 0) {
      setFormCaptchaError('');
    }

    if (error) {
      setFormButtonActive(true);
      return;
    }

    setFormButtonText('SENDING...');

    forms.submitContact(
      getFormEmail(),
      getFormSubject(),
      getFormMessage(),
      formCupMeda
    ).then(() => {
      setFormButtonText('SENT');
    }).catch(() => {
      setFormButtonText('Something happened. Try again!');
      setFormButtonActive(true);
    });

  }

  return (
    <>
      <Form className='contact-form' target="_self" onSubmit={onSubmit}>
        <input
          type='checkbox'
          name='_cup_meda'
          style={{ display: 'none' }}
          tabIndex={-1}
          autoComplete='off'
          checked={formCupMeda}
          onChange={(e: any) => setFormCupMeda(e.target.checked)}
        />
        <FormInput
          name='Your Email'
          error={formEmailError}
          required
          callback={(func: () => string) => getFormEmail = func}
        >
          Your@email.com
        </FormInput>
        <FormInput
          name='Name Your Problem'
          error={formSubjectError}
          required
          callback={(func: () => string) => getFormSubject = func}
        >
          Your problem
        </FormInput>
        <FormInput
          type='textarea'
          name='Describe Your Problem'
          error={formMessageError}
          required
          callback={(func: () => string) => getFormMessage = func}
        >
          Your text
        </FormInput>
        <FormCaptcha
          sitekey='6LfmKdAUAAAAAG5ixdrZuzF4Yk8CnpmzumaCHdFK'
          onChange={setCaptcha}
          error={formCaptchaError}
          name='Captcha'
        />
        <FormButton disabled={!formButtonActive}>
          {formButtonText}
        </FormButton>
      </Form>
    </>
  );
}

ContactFormElement.defaultProps = {

}

export default ContactFormElement;
