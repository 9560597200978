import { paths, pathnames } from "../../paths";
import { assets } from "../../assets";

export const global = {
  header: {
    logo: {
      image: assets.images.logo.white,
      path: paths.index
    },
    navbar: [
      {
        path: paths.about,
        pathname: pathnames[paths.about]
      },
      {
        path: paths.work,
        pathname: pathnames[paths.work]
      },
      {
        path: paths.careers,
        pathname: pathnames[paths.careers]
      },
    ],
  },
  footer: {
    socialMedia: [
      {
        name: 'Facebook',
        icon: assets.images.icons.facebook,
        link: 'https://www.facebook.com/2front.co'
      },
      {
        name: 'Instagram',
        icon: assets.images.icons.instagram,
        link: 'https://www.instagram.com/2front.co/'
      },
      {
        name: 'LinkedIn',
        icon: assets.images.icons.linkedin,
        link: 'https://www.linkedin.com/company/2front'
      },
    ],
    sitemap: [
      {
        path: paths.home,
        name: pathnames[paths.home]
      },
      {
        path: paths.about,
        name: pathnames[paths.about]
      },
      {
        path: paths.work,
        name: pathnames[paths.work]
      },
      {
        path: paths.careers,
        name: pathnames[paths.careers]
      },
      {
        path: paths.contact,
        name: pathnames[paths.contact]
      },
      {
        path: paths.privacy,
        name: pathnames[paths.privacy]
      },
    ],
    credit: {
      builtBy: '2Front',
      year: '2020',
      link: 'https://2front.co'
    }
  }
};
