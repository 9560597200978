import React from 'react';
import './home-card.scss';
import { Button } from '../Button';

type Props = {
  title: string,
  text: string,
  to: string,
  children?: React.ReactNode,
  style?: React.CSSProperties
}

const SmallCard: React.FC<Props> = (props) => {
  const {
    title,
    text,
    to,
    children,
    ...otherProps
  } = props;


  return (
    <div className='home-card small-home-card' {...otherProps} >
      <Button className='small-home-card-button' to={to} >
        {children}
      </Button>
      <Button className='small-home-card-button' to={to} >
        <h3>{title}</h3>
        <h4>{text}</h4>
      </Button>
    </div>
  );
}

SmallCard.defaultProps = {
  title: '',
  text: '',
  children: undefined,
  to: '/',
}

export default SmallCard;
