import React from 'react';
import './job-qualifications-section.scss';
import {
  SectionOneElement,
  Element
} from '../../../components';

type Props = {
  data: {
    title: string,
    qualifications: Array<string>,
    extras: Array<string>,
    responsibilities: Array<string>
  }
}

const JobQualificationsSection: React.FC<Props> = (props) => {
  const {
    data: {
      qualifications,
      title,
      extras,
      responsibilities
    }
  } = props;

  return (
    <>
      {qualifications.length !== 0 && responsibilities.length !== 0 &&
        <SectionOneElement>
          <Element>
            <h2>{title}</h2>
          </Element>
          {qualifications.length !== 0 &&
            <Element className='job-qualifications-element'>
              <h3>Qualifications: </h3>
              <ul>
                {qualifications.map(q => <li>- {q}</li>)}
              </ul>
            </Element>
          }
          {extras.length !== 0 &&
            <Element className='job-qualifications-element'>
              <p>Extra points for: </p>
              <ul>
                {extras.map(e => <li>- {e}</li>)}
              </ul>
            </Element>
          }
          {responsibilities.length !== 0 &&
            <Element className='job-qualifications-element'>
              <h3>Your responsibilities will be: </h3>
              <ul>
                {responsibilities.map(r => <li>- {r}</li>)}
              </ul>
            </Element>
          }
        </SectionOneElement>
      }
    </>
  );
}

JobQualificationsSection.defaultProps = {

}

export default JobQualificationsSection;
