import { PlaceholderState, PlaceholderStateContainer } from './types';

const getPlaceholderState = <S extends PlaceholderStateContainer>(state: S): PlaceholderState => {
  return state.placeholder;
}

export const getPlaceholder = <S extends PlaceholderStateContainer>(state: S): any => {
  const placeholderState = getPlaceholderState(state);
  return placeholderState.placeholder;
}
