import React from 'react';
import './office-life-section.scss';
import {
  SectionTwoElement,
  Element,
  SectionOneElement
} from '../../../components';

type Props = {
}

const OfficeLifeSection: React.FC<Props> = (props) => {

  return (
    <>
      <SectionOneElement>
        <Element>
          <h2>How is life at the Front?</h2>
          <p>
            Life at the Front is always exciting. Because our team is so small, work here is much more dynamic than in the bigger companies.
          </p>
        </Element>
      </SectionOneElement>
      <SectionTwoElement alignment='center'>
        <Element className='office-life-element'>
          <h3>Strong team bonds</h3>
          <p>Work in a small team, get to know and rely on your teammates. We don't do corporate mumbo jumbo.</p>
        </Element>
        <Element className='office-life-element'>
          <h3>Free health checks</h3>
          <p>We care about your health and will go to any length for our team member's health. Get your eyes checked.</p>
        </Element>
        <Element className='office-life-element'>
          <h3>Monthly teambuilding</h3>
          <p>Monthly leisure budget, get to know the team outside of office.</p>
        </Element>
        <Element className='office-life-element'>
          <h3>Flat hierarchy</h3>
          <p>We are a small company where everybody contributes to the outcome of a project. We choose our projects together as a team.</p>
        </Element>
        <Element className='office-life-element'>
          <h3>Extra education</h3>
          <p>We value constant growth and progress. Get a budget for personal growth and participate in seminars and conventions. </p>
        </Element>
        <Element className='office-life-element'>
          <h3>Mentorship program</h3>
          <p>You get a mentor when you first join and then grow and learn with them.
            Be prepared to one day become a mentor yourself.</p>
        </Element>
        <Element className='office-life-element'>
          <h3>Cleaning up after yourself</h3>
          <p>We take care of our environment and our workstations. We clean up after ourselves, cleaning makes us more aware.</p>
        </Element>
        <Element className='office-life-element'>
          <h3>Shared 2Front spirit</h3>
          <p>Get to share our work ethic. Grow together as a team member and as a person. Participate in shaping the future of 2Front!</p>
        </Element>
        <Element className='office-life-element'>
          <h3>Work remotely or on-site</h3>
          <p>You will always have a desk ready in our office, but you can work from wherever you like!</p>
        </Element>
      </SectionTwoElement>
    </>
  );
}

OfficeLifeSection.defaultProps = {

}

export default OfficeLifeSection;
