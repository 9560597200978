import React, { useEffect } from 'react';
import './footer.scss';
import {
  SectionTwoElement,
  SectionOneElement,
  SectionThreeElement
} from '../Section';
import { Element } from '../Element';
import { FooterSitemap } from './FooterSitemap';
import { FooterSocialMedia } from './FooterSocialMedia';
import { ScreenPaddedContainer } from '../ScreenPaddedContainer';
import { Button } from '../Button';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Image } from '../Image';

type Props = {
  className?: string,
  addScrollAction?: (action: () => void) => number,
  data: {
    socialMedia: Array<any>,
    sitemap: Array<any>,
    credit: any
  }
}


const Footer: React.FC<Props> = (props) => {
  const {
    className,
    data,
    addScrollAction
  } = props;

  const footerRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (addScrollAction) addScrollAction(() => {
      const wintop = $(window).scrollTop() || 0;
      const docheight = $(document).height() || 0;
      const winheight = $(window).height() || 0;
      const scrolltrigger = 0.93;
      const footer = footerRef.current;
      if ((wintop / (docheight - winheight)) >= scrolltrigger) {
        footer?.classList.add('footer-container-visible');
      } else {
        footer?.classList.remove('footer-container-visible');
      }
    });
  });

  return (
    <footer ref={footerRef} className={`footer-container ${className}`}>
      <ScreenPaddedContainer>
        <SectionTwoElement>
          <Element className='footer-element'>
            <FooterSocialMedia socialMedia={data.socialMedia} />
          </Element>
          <Element className='footer-element'>
            <FooterSitemap sitemap={data.sitemap} />
          </Element>
        </SectionTwoElement>
        <SectionOneElement>
          <Element className='footer-badge-container'>
            <Button
              className='footer-badge'
              href="https://www.appfutura.com/companies/2front-digital-agency"
              target="_blank"
            >
              <Image
                src="https://www.appfutura.com/img/badges/badge-top-app-company-grad-zagreb-gray.png"
                alt='Appfutura badge'
              />
            </Button>
            <Button
              className='footer-badge'
              href="https://clutch.co/profile/2front-digital-agency"
              target="_blank"

            >
              <Image
                src='https://clutch.co/badges/2018/2017_We_Deliver_White.png'
                alt='Clutch.co badge'
              />
            </Button>

            <Button
              className='footer-badge'
              href="https://www.goodfirms.co/company/2front-digital-agency"
              target="_blank"
            >
              <Image
                src="https://goodfirms.s3.amazonaws.com/badges/normal-badge/app-development.svg"
                alt="GoodFirms badge"
              />
            </Button>
          </Element>
        </SectionOneElement>
        <SectionOneElement className='footer-bottom'>
          <Element className='footer-element'>
            <p className='footer-component'>
              Copyright <Button
                href={data.credit.link}
                bold>
                {data.credit.builtBy}
              </Button> {data.credit.year} | Brands, Apps and Marketing Campaigns.
            </p>
          </Element>
        </SectionOneElement>
      </ScreenPaddedContainer>
    </footer>
  );
}

Footer.defaultProps = {
  className: '',
  addScrollAction: undefined
};

export default Footer;
