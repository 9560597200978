import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { createRootReducer, middleware } from '../../redux';

const store = () => createStore(
  createRootReducer(),
  middleware()
);

type Props = {
  element: React.ReactNode,
}

const ReduxWrapper: React.FC<Props> = ({ element }) => {
  return (
    <Provider store={store()}>
      {element}
    </Provider>
  );
}

export default ReduxWrapper;
