export const paths = {
  placeholder: '/404',
  index: '/',
  home: '/home',
  work: '/work',
  design: '/design',
  development: '/development',
  marketing: '/marketing',
  about: '/about',
  people: '/people',
  company: '/company',
  contact: '/contact',
  error404: '/404',
  privacy: '/privacy',
  cookies: '/cookies',
  guerilla: '/guerilla',
  getStarted: '/get-started',
  careers: '/careers',
  openJob: '/careers/open-job',
  wordpressDeveloper: '/careers/wordpress-developer'
}

export const pathnames = {
  [paths.placeholder]: 'Placeholder',
  [paths.index]: 'Home',
  [paths.home]: 'Home',
  [paths.work]: 'Work',
  [paths.design]: 'Design',
  [paths.development]: 'Development',
  [paths.marketing]: 'Marketing',
  [paths.about]: 'About',
  [paths.people]: 'People',
  [paths.company]: 'Company',
  [paths.contact]: 'Contact',
  [paths.error404]: 'Front not found',
  [paths.privacy]: 'Privacy',
  [paths.cookies]: 'Cookies',
  [paths.guerilla]: "This is not the business you're looking for...",
  [paths.getStarted]: 'Claim your free discovery session',
  [paths.careers]: 'Careers',
  [paths.openJob]: 'Open Job Application',
  [paths.wordpressDeveloper]: 'Wordpress Developer Application'
}
