import React from 'react';
import { SplashScreen } from '../../SplashScreen';

type WrappedComponentProps = {
  className?: string,
  data: {
    header: any,
    footer: any
  },
  title: string,
  currentPath: string,
  children?: React.ReactNode,
  leftSidebar?: React.ReactNode,
  rightSidebar?: React.ReactNode,
  addScrollAction?: (action: () => void) => number
}

const splashScreenHOC = (WrappedComponent: React.ComponentType<any>): React.FC<WrappedComponentProps> => {
  return (props: WrappedComponentProps) => {

    return (
      <>
        <SplashScreen />
        <WrappedComponent {...props as WrappedComponentProps} />
      </>
    );

  }
}

splashScreenHOC.defaultProps = {

}


export default splashScreenHOC;
