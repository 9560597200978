import React from 'react';
import './home-card.scss';
import { Button } from '../Button';

type Props = {
  children?: React.ReactNode,
  id?: string,
  title?: string,
  text?: string,
  right?: boolean,
  left?: boolean,
  cta?: string,
  to?: string,
}

const WideCard: React.FC<Props> = (props) => {
  const {
    children,
    title,
    right,
    left,
    text,
    cta,
    to,
    ...otherProps
  } = props;

  const titleClass = right ? 'home-card-title-right' : left ? 'home-card-title-left' : 'home-card-title-center';
  const bodyClass = right ? 'home-card-body-right' : left ? 'home-card-body-left' : 'home-card-body-center';

  return (
    <div className='home-card wide-home-card' {...otherProps}>
      <h3 className={titleClass} >
        {title}
      </h3>
      <p className={bodyClass}>
        {text}
        {children}
      </p>
      {cta &&
        <Button to={to} primary>
          {cta}
        </Button>
      }
    </div>
  );
}

WideCard.defaultProps = {
  children: undefined,
  title: '',
  text: '',
  id: undefined,
  right: false,
  left: false,
  cta: '',
  to: '/'
}

export default WideCard;
