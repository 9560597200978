import React from 'react';
import './newsletter-form-element.scss';
import { Element, Button } from '../../../components';
import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe';

type Props = {
  callback: () => void | null | undefined
}

const MailchimpForm = ({
  status,
  message,
  onValidated,
  callback
}: {
  status: "error" | "sending" | "success" | null,
  message: string | null,
  onValidated: (formData: EmailFormFields) => void,
  callback: () => void | null | undefined
}) => {

  let email: HTMLInputElement | null;

  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div>
      {status === 'sending' &&
        <div>
          subscribing...
        </div>
      }
      {status === 'error' && message &&
        <div>
          Something went wrong °O°!
        </div>
      }
      {status === 'success' && message &&
        <div>
          Thank you for subscribing ^_^!
        </div>
      }
      {status === 'success' && callback && callback()}
      <h4>Subscribe to our newsletter!</h4>
      <div className='newsletter-input-container'>
        <input
          ref={node => (email = node)}
          type="email"
          placeholder="Your email..."
          className='form-input'
        />
        <Button primary onClick={submit}>
          Submit
      </Button>
      </div>
    </div>
  );
};

const NewsletterFormElement: React.FC<Props> = (props) => {
  const { callback } = props;

  const url = 'https://2front.us8.list-manage.com/subscribe/post?u=b632a284bd68e40042b0ba44f&amp;id=165c92bdc6';

  return (
    <Element
      className='newsletter-form-container'
      id="mc_embed_signup"
    >
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <MailchimpForm
            onValidated={(formData: EmailFormFields) => subscribe(formData)}
            status={status}
            message={message}
            callback={callback}
          />
        )}
      />
    </Element>
  );
}

NewsletterFormElement.defaultProps = {

}

export default NewsletterFormElement;
