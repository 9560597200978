export const icons = {
  facebook: require('./icons-facebook.png'),
  instagram: require('./icons-instagram.png'),
  linkedin: require('./icons-linkedin.png'),
  adobeCC: require('./icons-adobe-cc.png'),
  drive: require('./icons-drive.png'),
  figma: require('./icons-figma.png'),
  gatsby: require('./icons-gatsby.png'),
  git: require('./icons-git.png'),
  github: require('./icons-github.png'),
  hangouts: require('./icons-hangouts.png'),
  php: require('./icons-php.png'),
  productive: require('./icons-productive.png'),
  react: require('./icons-react.png'),
  sass: require('./icons-sass.png'),
  slack: require('./icons-slack.png'),
  tslint: require('./icons-tslint.png'),
  typescript: require('./icons-typescript.png'),
  wordpress: require('./icons-wordpress.png'),
};
