import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  PlaceholderState,
  actionPlaceholderFetch
} from './types';

const initialState: PlaceholderState = {
  placeholder: undefined
}

const placeholder = createReducer(initialState)
  .handleType(actionPlaceholderFetch.request, (state: any, { payload }: any) => {
    return state;
  })
  .handleType(actionPlaceholderFetch.success, (state: any, { payload }: any) => {
    return [...state, payload];
  })
  .handleType(actionPlaceholderFetch.fail, (state: any, { payload }: any) => {
    return state;
  });

export default combineReducers({ placeholder });
