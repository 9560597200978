import React from 'react';
import './main-menu.scss';
import { Button } from '../Button';

type Props = {
  id?: string,
  className?: string,
  path: string,
  children?: React.ReactNode,
  active: string,
}

const MainMenuItem: React.FC<Props> = (props) => {
  const { id, className, path, children, active } = props;
  const isActive = path === active;

  return (
    <li id={id} className={`main-menu-item-wrapper ${className}`}>
      <Button to={path}>
        <p className={`main-menu-item main-menu-item-${isActive ? 'active' : ''}`}>
          {children}
        </p>
      </Button>
    </li>
  );
}

MainMenuItem.defaultProps = {
  id: undefined,
  className: '',
  path: '/',
}

export default MainMenuItem;
