import React, { useState, useEffect } from 'react';
import { Image } from '../Image';
import './splash-screen.scss';

type Props = {

}

const SplashScreen: React.FC<Props> = (props) => {

  const [hideSplash, setHideSplash] = useState('false');

  useEffect(() => {
    setHideSplash(window.sessionStorage.getItem('splash') || 'false');
    setTimeout(() => {
      setHideSplash('true');
      sessionStorage.setItem('splash', 'true');
      document.body.style.overflow = 'initial';
    }, 3500);
    document.body.style.overflowY = 'hidden';
  }, []);

  if (hideSplash === 'true') return null;

  return (
    <div className='splash-wrapper'>
      <div className='splash-container'>
        <Image className='splash-left' src='/images/splash_left.png' alt='2Front logo left part' />
        <Image className='splash-right' src='/images/splash_right.png' alt='2Front logo right part' />
      </div>
    </div>
  );
}

SplashScreen.defaultProps = {

}

export default SplashScreen;
