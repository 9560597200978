import React from 'react';
import './padded-section.scss';
import { ScreenPaddedContainer } from '../ScreenPaddedContainer';

type Props = {
  id?: string,
  className?: string
}

const PaddedSectionThreeElement: React.FC<Props> = (props: Props) => {
  const {
    className,
    ...otherProps
  } = props;

  return (
    <ScreenPaddedContainer>
      <div className={`section section-three-container ${className}`} {...otherProps} />
    </ScreenPaddedContainer>
  );
}

PaddedSectionThreeElement.defaultProps = {
  className: ''
}

export default PaddedSectionThreeElement;
