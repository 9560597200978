import { createAction } from "typesafe-actions"

export type PlaceholderState = {
  placeholder: any,
}

export interface PlaceholderStateContainer {
  placeholder: PlaceholderState,
}

export const actionPlaceholderFetch = {
  request: createAction('ACTION_PLACEHOLDER_FETCH_REQUEST', () => ({}))(),
  success: createAction('ACTION_PLACEHOLDER_FETCH_SUCCESS', (placeholder: any) => ({ placeholder }))(),
  fail: createAction('ACTION_PLACEHOLDER_FETCH_FAIL', () => ({}))()
}
