import _ from 'lodash';
import URI from 'urijs';


const defaultHeaders = {
  'Content-type': 'application/json',
  Accept: 'application/x.PBB.v1.0.0+json',
};

export const getAuthorizationHeader = (accessToken: string) => {
  return `Bearer ${accessToken}`;
}

const API_BASE_URL = "http://localhost:5000/api";

const composeRequestOptions = (config: any) => {
  const {
    authorization,
    headers = {},
    ...options
  } = config;

  if (authorization) {
    headers.Authorization = getAuthorizationHeader(authorization);
  }

  return {
    ...options,

    headers: new Headers({
      ...defaultHeaders,
      ...headers,
    }),
  };
}

export class RequestError extends Error {
  response: any;
  responseData: any;
  status: any;
  statusText: any;
  constructor(response: any, responseData: any) {
    super(response.statusText);
    this.response = response;
    this.responseData = responseData;
    this.status = response.status;
    this.statusText = response.statusText;
  }
}

export const checkResponseStatus = ([response, responseData]: any) => {
  if (!response.ok) {
    throw new RequestError(response, responseData);
  }

  return Promise.all([response, responseData]);
}

export const parseResponseData = (response: any) => {
  const contentTypeHeader = response.headers.get('content-type');
  const isJsonContent = contentTypeHeader && _.includes(contentTypeHeader, 'application/json');

  if (isJsonContent) {
    return Promise.all([response, response.clone().json()]);
  }

  return [response, {}];
}

export const buildRequestEndpoint = (path: any, params = {}) => {
  const endpoint = new URI(`${API_BASE_URL}${path}`);

  endpoint.protocol('http');
  endpoint.query(params);
  return endpoint.readable();
}

export const fetchRequest = (endpoint: any, config: any) => {
  const requestOptions = composeRequestOptions(config);

  return new Promise((resolve, reject) => (
    fetch(endpoint, requestOptions)
      .then(parseResponseData)
      .then(checkResponseStatus)
      .then(([response, responseJson]) => ( // eslint-disable-line no-unused-vars
        resolve(responseJson)
      ))
      .catch(error => {
        reject(error);
      })
  ));
}

export const isRequestUnauthorized = (error: any) => {
  return (_.get(error, 'status') === 401);
}

export const isRequestNetworkConnectionError = (error: any) => {
  const requestMessage = _.get(error, 'message');
  return (requestMessage === 'Network request failed');
}

export const isRequestServerError = (error: any) => {
  return (_.get(error, 'status') === 500);
}

export const createBody = (bodyObject: any) => {
  return JSON.stringify(bodyObject);
}

/*
export function isCacheValid(lastUpdated) {
  if (lastUpdated === -1) {
    return false;
  }

  const now = new Date().getTime();
  const isValid = now - lastUpdated < CACHE_EXPIRY;

  return isValid;
}
*/
