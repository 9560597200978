import React from 'react';
import './padded-section.scss';
import { ScreenPaddedContainer } from '../ScreenPaddedContainer';

type Props = {
  id?: string,
  className?: string,
  alignment?: 'right' | 'left' | 'center',
  negative?: boolean
}

const PaddedSectionTwoElement: React.FC<Props> = (props: Props) => {
  const {
    className,
    alignment,
    negative,
    ...otherProps
  } = props;

  let alignmentClass = '';
  if (alignment === 'left') {
    alignmentClass = 'section-two-left-container';
  } else if (alignment === 'right') {
    alignmentClass = 'section-two-right-container';
  } else {
    alignmentClass = 'section-two-container';
  }

  return (
    <ScreenPaddedContainer negative={negative}>
      <div className={`section ${alignmentClass} ${className}`} {...otherProps} />
    </ScreenPaddedContainer>
  );
}

PaddedSectionTwoElement.defaultProps = {
  className: '',
  alignment: 'center',
  negative: false
}

export default PaddedSectionTwoElement;
