import { global } from '../global';
import { paths, pathnames } from '../../paths';

export const cookies = {
  global,
  path: paths.cookies,
  pathname: pathnames[paths.cookies],
  content: {
    sections: [
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
      {
        type: '',
        elements: [
          {
            type: '',
            data: {

            }
          },
        ]
      },
    ]
  }
};
