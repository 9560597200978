export const floating = {
  branding1: require('./2front-branding-1.png'),
  branding2: require('./2front-branding-2.png'),
  graphicDesign1: require('./2front-graphic-design-1.png'),
  graphicDesign2: require('./2front-graphic-design-2.png'),
  graphicDesign3: require('./2front-graphic-design-3.png'),
  guerilla1: require('./2front-guerilla-1.png'),
  guerilla2: require('./2front-guerilla-2.png'),
  mobile1: require('./2front-mobile-1.png'),
  mobile2: require('./2front-mobile-2.png'),
  mobile3: require('./2front-mobile-3.png'),
  outsource1: require('./2front-outsource-1.png'),
  outsource2: require('./2front-outsource-2.png'),
  outsource3: require('./2front-outsource-3.png'),
  sm1: require('./2front-sm-1.png'),
  sm2: require('./2front-sm-2.png'),
  sm3: require('./2front-sm-3.png'),
  strategy1: require('./2front-strategy-1.png'),
  strategy2: require('./2front-strategy-2.png'),
  strategy3: require('./2front-strategy-3.png'),
  ux1: require('./2front-ux-1.png'),
  ux2: require('./2front-ux-2.png'),
  ux3: require('./2front-ux-3.png'),
  web1: require('./2front-web-1.png'),
  web2: require('./2front-web-2.png'),
  web3: require('./2front-web-3.png'),
  hasta1: require('./2front-hasta-1.png'),
  hasta2: require('./2front-hasta-2.png'),
  design1: require('./2front-design-1.png'),
  design2: require('./2front-design-2.png'),
  design3: require('./2front-design-3.png'),
  development1: require('./2front-development-1.png'),
  development2: require('./2front-development-2.png'),
  development3: require('./2front-development-3.png'),
  marketing1: require('./2front-marketing-1.png'),
  marketing2: require('./2front-marketing-2.png'),
  marketing3: require('./2front-marketing-3.png'),
  error4041: require('./404-not-found-1.png'),
  error4042: require('./404-not-found-2.png'),
  error4043: require('./404-not-found-3.png'),
  aboutCompany1: require('./2front-about-company-1.png'),
  aboutCompany2: require('./2front-about-company-2.png'),
  aboutCompany3: require('./2front-about-company-3.png'),
  aboutPeople1: require('./2front-about-people-1.png'),
  aboutPeople2: require('./2front-about-people-2.png'),
  aboutPeople3: require('./2front-about-people-3.png'),
  aboutCompanyMethods1: require('./2front-about-company-methods-1.png'),
  aboutCompanyMethods2: require('./2front-about-company-methods-2.png'),
  aboutCompanyMethods3: require('./2front-about-company-methods-3.png'),
  aboutCompanyMission1: require('./2front-about-company-mission-1.png'),
  aboutCompanyMission2: require('./2front-about-company-mission-2.png'),
  aboutCompanyMission3: require('./2front-about-company-mission-3.png'),
  aboutPeopleMore: require('./2front-about-people-more.png'),
  careerYou1: require('./2front-you-1.png'),
  careerYou2: require('./2front-you-2.png'),
  careerUs1: require('./2front-us-1.png'),
  careerUs2: require('./2front-us-2.png'),
  careerWe1: require('./2front-we-1.png'),
  careerWe2: require('./2front-we-2.png'),
  careerPositions1: require('./2front-positions-1.png'),
  careerPositions2: require('./2front-positions-2.png'),
  careerPositions3: require('./2front-positions-3.png'),
  careerSpirit1: require('./2front-spirit-1.png'),
  careerSpirit2: require('./2front-spirit-2.png'),
  careerSpirit3: require('./2front-spirit-3.png'),
};
