import React from 'react';
import './screen-padded-container.scss';

type Props = {
  id?: string,
  className?: string,
  negative?: boolean
}

const PlaceholderComponent: React.FC<Props> = (props) => {
  const { className, negative, ...otherProps } = props;

  return (
    <div className={`${negative ? 'negative' : ''}`}>
      <div
        className={`screen-padded-container ${className}`}
        {...otherProps}
      />
    </div>
  );
}

PlaceholderComponent.defaultProps = {
  className: '',
  negative: false
}

export default PlaceholderComponent;
