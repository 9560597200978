import React from 'react';
import './form.scss';

type Props = {
  target?: string,
  action?: string,
  className?: string,
  onSubmit?: (e: any) => void
}

const Form: React.FC<Props> = (props) => {
  const { className, ...otherProps } = props;

  return (
    <form className={`form ${className}`} {...props} />
  );
}

Form.defaultProps = {
  target: '_blank',
  action: ''
}

export default Form;
