import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import './cookie-prompt.scss';
import { Button } from '../Button';
import { model } from '../../model';

type Props = {

}

//cnsnt = CoNSeNT
const CONSENT_COOKIE = '2f_cnsnt';

const CookiePrompt: React.FC<Props> = (props) => {

  const cookies = new Cookies();

  let consentCookie = cookies.get(CONSENT_COOKIE);

  useEffect(() => {
    if (consentCookie) {
      const prompt = document.getElementById('cookie-prompt');
      if (prompt) prompt.className = 'cookie-prompt-container cookies-accepted';
    } else {
      const prompt = document.getElementById('cookie-prompt');
      if (prompt) prompt.className = 'cookie-prompt-container';

      cookies.addChangeListener((options) => {
        if (options.name === CONSENT_COOKIE) {
          consentCookie = options.value;
        }

        const prompt = document.getElementById('cookie-prompt');
        if (prompt) prompt.className = 'cookie-prompt-container cookies-accepted';
      });
    }
  }, []);

  const consent = () => {
    let expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);
    cookies.set(CONSENT_COOKIE, `${Math.random()}`, { expires: expiryDate });
    const prompt = document.getElementById('cookie-prompt');
    if (prompt) prompt.className = 'cookie-prompt-container cookies-accepted';
  }

  return (
    <div id='cookie-prompt' className='cookie-prompt-container cookies-accepted'>
      <p>
        We use cookies to make your life better.
            You can read more about them in our <Button to={model.paths.privacy} bold>Privacy policy</Button>!
      </p>
      <Button onClick={consent} accent>
        Love it
      </Button>
    </div>
  );
}

CookiePrompt.defaultProps = {

}

export default CookiePrompt;
