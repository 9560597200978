import React from 'react';

type Props = {
  className?: string,
  children?: React.ReactNode,
}

const FullWidthLayout: React.FC<Props> = (props) => {
  const { children } = props;
  return (
    <main>
      {children}
    </main>
  );
}

FullWidthLayout.defaultProps = {
  className: '',
  children: undefined
};

export default FullWidthLayout;
