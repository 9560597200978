import React from 'react';
import './form.scss';
import { Button } from '../Button';

type Props = {
  onClick?: any,
  disabled?: boolean,
}

const FormButton: React.FC<Props> = (props) => {

  return (
    <div className='form-element'>
      <Button type="submit" className='form-button' primary {...props} />
    </div>
  );
}

FormButton.defaultProps = {
  disabled: true,
}

export default FormButton;
