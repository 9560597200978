/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import $ from 'jquery';

const modernizr = "/scripts/modernizr/modernizr-custom.js";

const changeFavicon = (path: string) => {
  const link = document.createElement('link');
  //@ts-ignore
  link.type = 'image/x-icon';
  //@ts-ignore
  link.rel = 'shortcut icon';
  //@ts-ignore
  link.href = path;
  document.getElementsByTagName('head')[0].appendChild(link);
}


type Props = {
  description?: string,
  lang?: string,
  meta?: [],
  title: string,
  children?: React.ReactNode
}

const initialProps = {
  description: '',
  lang: 'en',
  meta: [],
  title: '',
}

const Head: React.FC<Props> = ({ description = '', lang = 'en', meta = [], title, children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  useEffect(() => {
    $(document).ready(function ($) {
      // Get page title
      var pageTitle = $("title").text();

      // Change page title on blur
      $(window).blur(function () {
        $("title").text("▶ Get back 2Front!");
        changeFavicon('/favicon/favicon-red-32.png');
      });

      // Change page title back on focus
      $(window).focus(function () {
        $("title").text(pageTitle);
        changeFavicon('/favicon/favicon-32.png');
      });
    });
  }, []);



  const metaDescription = description || site.siteMetadata.description;
  const smTitle = `${title} | ${site.siteMetadata.title} Digital Agency`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title} Digital Agency`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: smTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: smTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {children}
    </Helmet>
  )
}

Head.defaultProps = {
  children: undefined
}


export default Head;
