import React from 'react';
import './form.scss';
import ReCAPTCHA from 'react-google-recaptcha';

type Props = {
  onChange: any,
  sitekey: string,
  name?: string,
  error?: string,
  required?: boolean
}

const FormCaptcha: React.FC<Props> = (props) => {
  const {
    onChange,
    error,
    name,
    sitekey,
    ...otherProps
  } = props;

  return (
    <div className='form-element form-element-captcha'>
      {name && <label className='form-element-title'>{name}</label>}
      <ReCAPTCHA
        sitekey={sitekey}
        onChange={onChange}
        {...otherProps}
      />
      {error && <div className='form-error'>{error}</div>}
    </div>
  );
}

FormCaptcha.defaultProps = {

}

export default FormCaptcha;
