import React from 'react';
import './section.scss';

type Props = {
  id?: string,
  className?: string
}

const SectionOneElement: React.FC<Props> = (props: Props) => {
  const {
    className,
    ...otherProps
  } = props;

  return (
    <div className={`section section-one-container ${className}`} {...otherProps} />
  );
}

SectionOneElement.defaultProps = {
  className: ''
}

export default SectionOneElement;
