import React, { RefObject } from 'react';
import './main-menu.scss';
import MainMenuItem from './MainMenuItem';

type Props = {
  id: string,
  children: React.ReactNode,
  data: Array<{
    path: string,
    pathname: string
  }>,
  active: string,
  ref?: RefObject<HTMLUListElement> | undefined,
}

const MainMenu: React.FC<Props> = React.forwardRef((props, reference) => {
  const {
    id,
    ref,
    children,
    active,
    data,
    ...otherProps
  } = props;

  return (
    <ul id={id} ref={reference} className='main-menu' {...otherProps}>
      {children}
      {data.map(item => <MainMenuItem active={active} path={item.path}>
        {item.pathname}
      </MainMenuItem>)}
    </ul>
  );
});

MainMenu.defaultProps = {

}

export default MainMenu;
