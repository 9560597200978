import React from 'react';
import './padded-section.scss';
import { ScreenPaddedContainer } from '../ScreenPaddedContainer';

type Props = {
  id?: string,
  className?: string,
  negative?: boolean
}

const PaddedSectionOneElement: React.FC<Props> = (props: Props) => {
  const {
    className,
    negative,
    ...otherProps
  } = props;

  return (
    <ScreenPaddedContainer negative={negative}>
      <div className={`section section-one-container ${className}`} {...otherProps} />
    </ScreenPaddedContainer>
  );
}

PaddedSectionOneElement.defaultProps = {
  className: '',
  negative: false,
}

export default PaddedSectionOneElement;
