import React from 'react';
import './footer-sitemap.scss';
import { Button } from '../../Button';

type Props = {
  text: string,
  to: string,
}

const FooterSitemapItem: React.FC<Props> = (props) => {
  const { text, to, ...otherProps } = props;

  return (
    <li className='footer-sitemap-item'>
      <Button to={to} {...otherProps}>
        {text}
      </Button>
    </li>
  );
}

FooterSitemapItem.defaultProps = {

}

export default FooterSitemapItem;
