import React from 'react';
import './culture-fit-section.scss';
import {
  SectionTwoElement,
  Element,
  FloatingImage,
  PaddedSectionTwoElement
} from '../../../components';
import { images } from '../../../assets';

type Props = {

}

const CultureFitSection: React.FC<Props> = (props) => {

  return (
    <>
      <PaddedSectionTwoElement alignment='center'>
        <Element className='culture-fit-element'>
          <FloatingImage top={images.floating.careerYou1} bottom={images.floating.careerYou2} alt='Your before 2Front' />
        </Element>
        <Element className='culture-fit-element'>
          <h2>You?</h2>
          <p>You are dedicated, driven and inquisitive. You care about getting the job done, and are not afraid to push the limits of what is possible.</p>
        </Element>
        <Element className='culture-fit-element'>
          <FloatingImage middle={images.floating.careerUs1} bottom={images.floating.careerUs2} alt='2Front before you' />
        </Element>
        <Element className='culture-fit-element'>
          <h2>Us?</h2>
          <p>We stop at nothing to ensure the absolute communication between our clients and their audiences!</p>
        </Element>
        <Element className='culture-fit-element'>
          <FloatingImage top={images.floating.careerWe1} bottom={images.floating.careerWe2} alt='2Front with you' />
        </Element>
        <Element className='culture-fit-element'>
          <h2>We?</h2>
          <p>can create great things together <br /> and enjoy doing it.</p>
        </Element>
      </PaddedSectionTwoElement>
    </>
  );
}

CultureFitSection.defaultProps = {

}

export default CultureFitSection;
