import { networking } from "../../services";
import {
  actionPlaceholderFetch,
  PlaceholderStateContainer
} from "./types";
import { Dispatch } from "redux";

export const placeholderAction = () => {
  return <S extends PlaceholderStateContainer>(dispatch: Dispatch, getState: () => S) => {

    const fetchConfig = {
      method: "GET",
      placeholder: getState().placeholder.placeholder,
    };

    dispatch(actionPlaceholderFetch.request());

    return networking.fetchRequest("placeholder endpoint", fetchConfig)
      .then(payload => dispatch(actionPlaceholderFetch.success(payload)))
      .catch(error => dispatch(actionPlaceholderFetch.fail()));
  }
}
