import React, { useEffect } from 'react';
import '../../theme/index.scss';
import './layout.scss';
import {
  NavBar,
  Footer,
  CookiePrompt,
  Head,
  PaddedColumnBodyLayout,
  splashScreenHOC,
  animatedBackgroundHOC,
  onScrollHOC,
} from '../../components';
import { NewsletterPoppupModal } from '../modals';

type Props = {
  className?: string,
  data: {
    header: any,
    footer: any
  },
  title: string,
  currentPath: string,
  children?: React.ReactNode,
  leftSidebar?: React.ReactNode,
  rightSidebar?: React.ReactNode,
  addScrollAction?: (action: () => void) => number
}

const PageLayout: React.FC<Props> = (props) => {
  const {
    className,
    data,
    title,
    children,
    currentPath,
    addScrollAction,
    ...otherProps
  } = props;

  useEffect(() => {
    document.body.style.overflow = 'initial';
    if (!window.sessionStorage.getItem('splash')) {
      document.getElementsByTagName('h1')[0].classList.add('animate-headline');
    }
  }, []);


  return (<>
    <div className={`page-layout ${className}`}>
      <Head title={title} />
      <div className='hud-wrapper'>
        <NavBar
          currentPath={currentPath}
          data={data.header}
          addScrollAction={addScrollAction}
        />
      </div>
      <CookiePrompt />
      <div className='body-wrapper'>
        <PaddedColumnBodyLayout {...otherProps}>
          {children}
        </PaddedColumnBodyLayout>
      </div>
      <div className='footer-wrapper'>
        <Footer
          data={data.footer}
          addScrollAction={addScrollAction}
        />
      </div>
      <NewsletterPoppupModal />
    </div>
  </>
  );
}

PageLayout.defaultProps = {
  className: '',
  children: undefined
};

export default onScrollHOC(animatedBackgroundHOC(splashScreenHOC(PageLayout)));
