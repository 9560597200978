import React from 'react';
import './job-list-section.scss';
import {
  SectionOneElement,
  Element,
  FloatingImage
} from '../../../components';
import { CareerElement } from '../../elements';

type Props = {
  data: {
    image: {
      top?: string,
      middle?: string,
      bottom?: string,
      alt: string
    },
    jobs: Array<{
      link: string,
      title: string,
      location: string,
      image: any
    }>,
    title: string
  },
  id?: string,
}

const JobListSection: React.FC<Props> = (props) => {
  const { id, data } = props;

  return (
    <SectionOneElement id={id} >
      <Element>
        <FloatingImage
          top={data.image.top}
          middle={data.image.middle}
          bottom={data.image.bottom}
          alt={data.image.alt}
        />
        <h2>{data.title}</h2>
      </Element>
      {data.jobs.map(job => <CareerElement data={job} />)}
    </SectionOneElement>
  );
}

JobListSection.defaultProps = {

}

export default JobListSection;
