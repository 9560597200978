import React from 'react';
import './footer-social-media.scss';
import { Button } from '../../Button';
import { Image } from '../../Image';

type Props = {
  title: string,
  icon: string,
  link: string,
}

const FooterSMItem: React.FC<Props> = (props) => {
  const { title, icon, link, ...otherProps } = props;

  return (
    <li className='sm-item'>
      <Button href={link} {...otherProps} >
        <Image src={icon} alt={`${title} icon`} />
        <p>
          {title}
        </p>
      </Button>
    </li>
  );
}

FooterSMItem.defaultProps = {
}

export default FooterSMItem;
