import React, { ReactElement } from 'react';
import './google-map.scss';
import GoogleMapComponent from './GoogleMapComponent';

type Props = {
}

const GoogleMap: React.FC<Props> = (props) => {
  const {

  } = props;

  return (
    <GoogleMapComponent
      googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBAbsmr0KDzyt8EcCS8YzukYeHUIompwKE&v=3.exp&libraries=geometry,drawing,places'
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: '400px', textAlign: 'center' }} />}
      mapElement={<div style={{ height: '100%', width: '90%', marginLeft: '5%' }} />}
      {...props}
    />
  );
}

GoogleMap.defaultProps = {

}

export default GoogleMap;
